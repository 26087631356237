import { Application } from "@hotwired/stimulus"
import HoneyBadger from '@honeybadger-io/js'

// TODO improve Honeybadger reporting to make it more readable
HoneyBadger.configure({
  apiKey: window._honeybadger_api_key,
  environment: process.env.NODE_ENV,
  revision: 'git SHA/project version',
})

const application = Application.start()

application.handleError = (error, message, detail) => {
  if (process.env.NODE_ENV != 'production' && typeof console !== 'undefined' && console.warn) {
    console.warn(message, detail);
  }
  HoneyBadger.notify(error)
}

// Configure Stimulus development experience
application.debug    = false
window.Stimulus      = application

// if(process.env.NODE_ENV == 'production'){
//   var console = {};
//   console.log = function(){};
//   console.warn = function(){};
//   console.error = function(){};
//   console.table = function(){};
//   console.assert = function(){};
//   console.count = function(){};
//   console.debug = function(){};
//   console.dir = function(){};
//   console.dirxml = function(){};
//   console.exception = function(){};
//   console.group = function(){};
//   console.groupCollapsed = function(){};
//   console.info = function(){};
//   console.profile = function(){};
//   console.table = function(){};
//   console.timeLog = function(){};
//   console.timeStamp = function(){};
//   console.trace = function(){};
//   window.console = console;
// }

export { application }
